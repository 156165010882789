import React from 'react';
import ArrayChunks from './ArrayChunks';
import PortfolioItem from './PortfolioItem';

export default function PortfolioList(props) {
    const { items } = props;
    const rows = ArrayChunks(items, 2);

    if(items.length === 0) {
        return (
            <div>
                <h3>Sorry, no portfolio items to display at the moment...</h3>
            </div>
        )
    }

    return (
        <div class="container">
                {rows.map((row, index) => (

                    <div key={index} className="row port-row">
                        {
                            row.map((col, index) => (
                                <PortfolioItem key={index} item={col} />
                            ))
                        }
                    </div>

                ))}
        </div>
    )
}
