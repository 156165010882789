

export default [
    {
        name: "Your Style Boutique",
        image: "/images/screenshots/ecommerceProject/yourStyleBoutique3.png",
        desc: "A women's fashion webshop built with WooCommerce. Supports credit card and Paypal payments and is connected to an API for adding products from an external platform",
        project: "//yourstyleboutique.com/",
    },
    {
        name: "Skin Care Shop",
        image: "/images/screenshots/ecommerceProject/ecommerce2.png",
        desc: "This project was made with React, Bootstrap and the GSAP animation library... You can click on the Github icon to see the code, or go straight to the live project.",
        project: "//skincare-webshop.netlify.com/",
        github: "//github.com/digitalNomadRudolf/skin-care-webshop"
    },
    /* {
        name: "Yacht Charter Reviews",
        image: "/images/screenshots/boatCharter/boatCharter.png",
        desc: "This project was made with WordPress. A custom theme was created and all the functionality was built from scratch. See the code on Github or go to the website directly.",
        project: "//boatcharterreviews.000webhostapp.com/",
        github: "//github.com/digitalNomadRudolf/wordpress-yachtcharter-reviews"
    }, */
    {
        name: "Property Finder",
        image: "/images/screenshots/propertyFinder/propertyFinder.png",
        desc: "The property finder app allows for filtered searching and was built with React. To access the state easily in various components i used the Context API.",
        project: "//caribbean-real-estate.netlify.com/",
        github: "//github.com/digitalNomadRudolf/caribbean-real-estate"
    },
    {
        name: "React Restaurant Theme",
        image: "/images/screenshots/restaurantProject/restaurant3.png",
        desc: "After creating this website with HTML CSS and JS I converted it to React. It includes a React Datepicker. I added a contact form that is fully functional and uses NodeJS with Nodemailer.",
        project: "//react-restaurant-theme.netlify.com/",
        github: "//github.com/digitalNomadRudolf/react-restaurant-theme"
    },
    /* {
        name: "Weight Loss Reviews",
        image: "/images/screenshots/weightLossProject/Screenshot2.png",
        desc: "Weight Loss Reviews is a custom made Wordpress theme that lists various weightloss products and programs. It includes a custom made Mega Menu navigation fully built from scratch using the WP nav walker class.",
        project: "//weightlossreviews.onlinewebshop.net",
        github: "//github.com/digitalNomadRudolf/wordpress-weightlossreview-theme"
    }, */
    {
        name: "Stripe Payment Page",
        image: "/images/screenshots/landingPageWithStripe/newLandingPage.png",
        desc: "A small app i made to experiment with Stripe. Stripe allows for payments to be made with VISA, MasterCard and American Express. This is very valuable for ecommerce websites. I used Gatsby to setup this website.",
        project: "//digital-marketing-shop.netlify.com/",
        github: "//github.com/digitalNomadRudolf/seo-product-shop"
    },
    {
        name: "A Dutch Creative Agency",
        image: "/images/screenshots/oldWork/bo2d.png",
        desc: "This website was developed for a Media / Marketing Agency in the Netherlands.",
        project: "#",
        github: "#"
    }
];