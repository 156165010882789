import React, { Component } from 'react';
import $ from 'jquery';

export default class Contact extends Component {
    state = {
        inputEmail: '',
        inputMessage: '',
        inputName: '',
        isErrorShown: false,
        isFormValid: false,
        isFormSubmitted: false,
        isEmailValid: false,
    }

    isValidEmail = email => {
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return reg.test(String(email).toLowerCase())
    }

    handleInput = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleFormSubmit = (event) => {
        event.preventDefault();

        // Test
        if (this.state.inputEmail.length > 0 && this.isValidEmail(this.state.inputEmail) && this.state.inputName.length > 0 && this.state.inputMessage.length > 0) {
            this.setState({
                isErrorShown: false,
                isEmailValid: true,
                isFormValid: true,
            })

            // Send the form with AJAX
            $.ajax({
                data: this.state,
                type: 'POST',
                url: '../mailer.php',
                success: function (data) {
                    console.info(data)
                },
                error: function (xhr, status, err) {
                    console.error(status, err.toString())
                }
            })

            // Reset state after sending the form
            this.setState({
                inputEmail: '',
                inputMessage: '',
                inputName: '',
                isErrorShown: false,
                isFormValid: false,
                isFormSubmitted: true,
                isEmailValid: false,
            })

        } else {
            // Show error message
            this.setState({
                isErrorShown: true
            })
        }
    }

    render() {
        const { inputName, inputEmail, inputMessage } = this.state;

        return (
            <section id="contact-me">
                <div className="container">
                    <div className="row">
                        <div className="contact-me__why col-md-4">
                            <h1 className="contact-title">Get in touch</h1>
                            <div className="contact-img-wrap">
                                <img src='/images/avatar.jpg' className="avatar" alt="avatar-me" />
                            </div>

                            <span className="info">Eager to work with me on your own project? Do you need a passionate Web Developer on your team?</span>
                            <p>Send me an e-mail or reach out to me directly.</p>
                        </div>

                        <div className="contact-me__how col-md-8">
                            <h1 className="intro">Contact</h1>
                            <p className="intro">Always happy to help you with your project</p>

                            <form method="post" className='contact-me-form'>
                                <div className="form-input">
                                    <label>
                                        <span className="text">Name</span>
                                    </label>
                                    <span className="input-wrap">
                                        <input type="text" value={inputName} name="inputName" id='inputName' className="name" onChange={this.handleInput} required={true} />
                                    </span>
                                </div>

                                <div className="form-input">
                                    <label>
                                        <span className="text">Email</span>
                                    </label>
                                    <span className="input-wrap">
                                        <input type="email" name="inputEmail" value={inputEmail} id='inputEmail' className="email" onChange={this.handleInput} required={true} />
                                    </span>
                                </div>

                                <div className="form-input">
                                    <label>
                                        <span className="text">Message</span>
                                    </label>
                                    <span className="input-wrap">
                                        <textarea name="inputMessage" id='inputMessage' className="contact-textarea" value={inputMessage} cols="40" rows="10" onChange={this.handleInput} required={true}></textarea>
                                    </span>
                                </div>

                                <div className="captcha-div">

                                </div>

                                {this.state.isFormSubmitted && (
                                    <fieldset>
                                        <p>Thank you for contacting me. I will get back to you soon.</p>
                                    </fieldset>
                                )}

                                {this.state.isErrorShown && (
                                    <fieldset>
                                        <p>Please, make sure to fill all fields.</p>
                                    </fieldset>
                                )}

                                <div className="submit-btn">
                                    <input type="submit" value="Send" className="contact-submit" onClick={this.handleFormSubmit} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
