import React from 'react';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import items from './data';
import Portfolio from './components/Portfolio';
import './scss/main.scss';
import HomeContent from './components/HomeContent';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div id="wrapper">
      <section id="introduction">
        <NavBar />
        <Hero />
      </section>
      <Portfolio items={items} />
      <HomeContent />
      <Contact />
      <Footer />

    </div>
  );
}

export default App;
