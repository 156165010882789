import React from 'react';
import Download from './Download';

export default function Hero() {
    return (
        <div class="main-hero">

            <div class="main-title">
                <h1 class="heading">I'm Rudolf and I'm a Web Developer</h1>
                <div class="main-title__descr">
                    <span class="secondary-descr">
                        building your website or web-app is my passion...
                    </span>

                    <button class="cta-work"><a href="#portfolio">
                        <h1 class="cta-work__heading">See my work</h1>
                    </a>
                    </button>

                    <button class="cta-resume">
                        <h1 class="cta-resume__heading"><Download /></h1>
                    </button>


                </div>

            </div>
        </div>
    )
}
