import { type } from "os";

export default function ArrayChunks(arr, size) {
    if (!Array.isArray(arr)) {
        throw new TypeError('Input should be an array');
    }
    if (typeof size !== 'number') {
        throw new TypeError('Size should be a number');
    }

    var result = [];
    for (var i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, size + i));
    }

    return result;
};