import React from 'react';

export default function Skills() {
    return (
        <div class="container">
            <div class="row">
                <div class="skills-section">
                    <div class="skills-section__title col-md-6">
                        <h1>Some of my skills</h1>

                        <ul class="skill-list-top">
                            <li><img src='/images/icons/html-5.png' alt="html logo" class="img-responsive logo" /></li>
                            <li><img src='/images/icons/css.png' alt="css logo" class="img-responsive logo" /></li>
                            <li><img src='/images/icons/iconfinder_288_Sass_logo_4375066.png' alt="sass logo" class="img-responsive logo" /></li>
                            <li><img src='/images/icons/javascript.png' alt="js logo" class="img-responsive logo" /></li>
                        </ul>
                        <ul class="skill-list-btm">
                            <li><img src='/images/icons/iconfinder_React.js_logo_1174949.png' alt="react logo" class="img-responsive logo" /></li>
                            <li><img src='/images/icons/iconfinder_wordpress_294649.png' alt="wordpress logo" class="img-responsive logo" /></li>
                            <li><img src='/images/icons/github-logo.png' alt="github logo" class="img-responsive" /></li>
                            <li><img src='/images/icons/iconfinder_JQuery_logo_282806.png' alt="jQuery logo" class="img-responsive logo" /></li>
                        </ul>
                        <ul class="skill-list-btm">
                            <li><img src='/images/icons/iconfinder_php_282805.png' alt="php logo" class="img-responsive logo" /></li>
                        </ul>
                    </div>


                    <div class="skills-section__ui col-md-6">
                        <h1>I build websites that work</h1>

                        <div class="main-text-block sm-text-block">
                            <p>My passion is actually making sure that you have a website that gives you business or adds value to your business. Does it run fast? Is your customer actually using it and is he/she having a good user experience? Does your site/web-app generate profits for you? These questions need to be answered with a YES. Lots of business owners have a website that doesn't actually convert or it works poorly. My passion is to make sure that your Website/Web-app is an Asset. Not just an extra cost.
                                See <a href="#portfolio">my work</a> to get an idea of what i do, or <a href="#contact-me">ask me to help you</a> with your project(s).
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row extra-pad">
                <div class="skills-section__front-end col-md-6">
                    <h1>Front End Development</h1>
                    <div class="main-text-block sm-text-block">
                        <p>Since i started building web applications in 2010 i have built countless websites with main technologies HTML5, CSS2/3, JavaScript and PHP. Using WordPress as the content management system.</p>
                        <strong>Your project should be clean, performant and easy to adjust.</strong><br /><br />
                        <p>In order to create more structure and efficiency to the project and the codebase, I often use the React library. To divide the project into single purpose components that make it easier to understand and better organized.</p>
                        <p>I have built some fast UI's and improved on the general performance and structure of many web-applications.</p>
                        <span class="cta-txt">Check out how i use these technologies in <a href="#portfolio">my projects</a></span>
                    </div>
                </div>



                <div class="skills-section__front-end col-md-6">
                    <h1>WordPress</h1>
                    <div class="main-text-block sm-text-block">
                        <p>With one of my main expertises, WordPress and WooCommerce, I will build any webshop or website tailored to your needs. Along with the Elementor and Divi pagebuilders (and others), I will build your website just the way you want it.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
