import React from 'react';
import Download from './Download';

export default function NavBar() {
    return (
        <div className="navbar-wrap">
            <div className="navbar-wrap__inner">
                <div className="logo">
                    <a href="/">
                    digitalNomadRudolf
                    </a>
                </div>
                <nav className="navbar-right">
                
                        <input type="checkbox"
                               className="openSidebarMenu"
                               id="openSidebarMenu" />
                        <label htmlFor="openSidebarMenu"
                               className="sidebarIconToggle">
                                   <div className="spinner diagonal part-1"></div>
                                   <div className="spinner horizontal"></div>
                                   <div className="spinner diagonal part-2"></div>
                               </label>

                    <div id="menuToggle" aria-label="Menu">
                        <ul id="main-menu" className="right-nav">
                            <li className="nav-item"><a href="#portfolio">Portfolio</a></li>
                            <li className="nav-item"><Download /></li>
                            <li className="nav-item"><a href="#contact-me">Hire me</a></li>
                        </ul>
                    </div>
                    
                </nav>
            </div>
        </div>
    )
}
