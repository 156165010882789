import React from 'react';
import PortfolioList from './PortfolioList';

export default function Portfolio(props) {
    const { items } = props;
    return (
        <section id="portfolio" className="portfolio">
            <PortfolioList items={items} />
        </section>
    )
}
