import React from 'react';
import Skills from './Skills';

export default function HomeContent() {
    return (
        <section id="secondary-intro">

            <Skills />

            <div class="container">
                <div class="row">
                    <div class="working-for">
                        <h1 class="working-for__heading">Work with me</h1>
                        <div class="row">
                            <div class="working-for__step--1 col-md-4">
                                <h3>From concept to web app</h3>
                                <span class="text-block">
                                    <p>Brainstorming sessions about project and design ideas. Whether you need small changes to an existing project, or a brand new website project, I will dive in and realise your goals.</p>
                                </span>
                            </div>

                            <div class="working-for__step--2 col-md-4">
                                <h3>Remote or on location</h3>
                                <span class="text-block">
                                    <p>Based on the requirements for your project I will either work remotely or with your team as requested. In the past years i have worked with various people and helped deliver multiple projects.</p>
                                </span>
                            </div>

                            <div class="working-for__step--3 col-md-4">
                                <h3>Work Ethic</h3>
                                <span class="text-block">
                                    <p>Working with me you will know three things for sure:
                                        <ul class="working-w-me">
                                            <li>Together we will find out what your business/project needs to succeed, and I will move heaven and earth to   realise that</li>
                                            <li>I will do whatever it takes to get the job done.</li>
                                            <li>I will work either solo or together with your awesome team depending on the project requirements</li>
                                        </ul>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
