import React, { Component } from 'react';
import Pdf from '../documents/WebDeveloperRudolfResume.pdf';

export default class Download extends Component {
    render() {
        return (
            <div>
                <a href={Pdf} target="_blank">Resume</a>
            </div>
        )
    }
}
