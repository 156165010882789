import React from 'react';
import { Link } from 'react-router-dom';
import 'react-image-lightbox/style.css';
import PortfolioLightbox from './PortfolioLightbox';

export default function PortfolioItem({ item }) {
    const { image, name, desc, project, github } = item;

    // render the image lightbox for the dutch creative agency portfolio item
    // if the name equals the name of the correct item, then render the code for the lightbox component
    if(name === "A Dutch Creative Agency") {
        return (
            <div className="col-md-6 port-column">
                    <div className="image-container">
                        <img src={image} alt="project-image" className="img-responsive" />
                    <div className="image-overlay">
                    
                                <div className="buttons-wrap">
                                   {/*  <Link to={github}>
                                 <button className="github-code">
                                     <i className="fab fa-github" alt="see the code"></i>
                                 </button>
                                    </Link> */}

                                    <PortfolioLightbox />
                                </div>
                             </div>
                    

                    <div className="hidden-content">
                            <div className="project-title">
                                <h1>{name}</h1>
                            </div>
                            <div className="proj-description">
                                <p>{desc}
                                </p>
                            </div>
                    </div>

                    </div>
                </div>
        )
    } else {
    return (
        <div className="col-md-6 port-column">
                    <div className="image-container">
                        <img src={image} alt="project-image" className="img-responsive" />
                    <div className="image-overlay">
                    
                                <div className="buttons-wrap">
                                    <Link to={github} target="_blank">
                                 <button className="github-code">
                                     <i className="fab fa-github" alt="see the code"></i>
                                 </button>
                                    </Link>

                                    <Link to={project} target="_blank">
                                 <button className="goto-project">
                                     view project
                                 </button>
                                    </Link>
                                </div>
                             </div>
                    

                    <div className="hidden-content">
                            <div className="project-title">
                                <h1>{name}</h1>
                            </div>
                            <div className="proj-description">
                                <p>{desc}
                                </p>
                            </div>
                    </div>

                    </div>
                </div>
    )
  }
}
